import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import CloudDownload from '@material-ui/icons/CloudDownload';
import i18n from 'assets/i18n';
import util from 'util';
import Auth from 'modules/Auth';
import { openSnackbar } from 'components/common/bars/SnackBar';
import VisitorPage from 'containers/dashboard/VisitorPage';
import VisitPage from 'containers/dashboard/visitor/VisitPage';
import DocumentsPage from 'containers/dashboard/documents/DocumentsPage';
import ItineraryPage from 'containers/dashboard/visitor/ItineraryPage';
import ExplorePage from 'containers/dashboard/visitor/ExplorePage';
import ReportsPage from 'containers/dashboard/reports/ReportsPage';
import SettingsPage from 'containers/settings/SettingsPage';
import MeetingRoomsPage from 'containers/calendar/MeetingRoomsPage';
import { CompanyDataProvider, TabbedView, VmsButtonFAB } from 'components';
import commonStyles from 'assets/jss/commonStyles';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withBus } from 'react-bus';
import {
  visitor_tab_routes,
  employee_tab_routes,
  routes,
  assistant_tabs,
  user_roles,
  porter_tab_routes,
  globaladmin_tab_routes,
} from 'AppSettings';
import { VisitService, TemplateService, UserAssistantService } from 'services';
import MyVisits from './dashboard/MyVisits/MyVisits';
import emptyImage from 'assets/jss/emptyImage';
import classNames from 'classnames';

import MyVisit from 'assets/images/MyVisit.jpg';
import Visit from 'assets/images/Visit.jpg';
import Itinerary from 'assets/images/Itinerary.jpg';
import Document from 'assets/images/Document.jpg';
import fileDownload from 'js-file-download';
import moment from 'moment';
import produce from 'immer';

const styles = (theme) => ({
  ...commonStyles(theme),
  flex: {
    flex: 1,
  },
  tabs: {
    boxShadow: theme.shadows[5],
  },
  tab: {
    color: theme.palette.primary6,
  },
  exportVisits: {
    color: theme.palette.white,
    lineHeight: 0,
  },
  noMyVisitData: emptyImage(MyVisit),
  noVisitData: emptyImage(Visit),
  noItineraryData: emptyImage(Itinerary),
  noDocumentData: emptyImage(Document),
});

class DashboardPage extends Component {
  /**
   * Class constructor.
   */
  constructor(props) {
    super(props);

    let initialIndex = this.getTabIndex();

    this.state = {
      snackbarOpen: false,
      snackbarMsg: '',
      openSafetyTriainingsEditor: false,
      selectedQuestion: '<p>My <strong>Question</strong></p>',
      selectedAnswers: [
        { isCorrect: true, answer: 'Correct answer' },
        { isCorrect: false, answer: 'Incorrect answer' },
      ],
      indexOfDashboardTab: initialIndex,
      selectedVisit: null,
      exportMyVisitsType: null,
      hasData: {},
      showBadges: [], //Used for displaying a badge on tabs
    };
    this.referrer = ((this.props.location || {}).state || {}).referrer || {};
    this.lastSettingsPage = routes.SETTINGS_USERS;
  }

  getTabIndex() {
    const {
      visitorView,
      location: { pathname },
    } = this.props;

    let tabIndex;
    if (Auth.isUserVisitor() || visitorView) {
      tabIndex = visitor_tab_routes.indexOf(pathname);
    } else if (Auth.isUserPorter()) {
      tabIndex = porter_tab_routes.indexOf(pathname);
    } else if (Auth.isUserGlobalAdmin()) {
      tabIndex = globaladmin_tab_routes.indexOf(pathname);
    } else {
      tabIndex = employee_tab_routes.indexOf(pathname);
    }

    if (
      tabIndex < 0 &&
      !Auth.isUserVisitor() &&
      pathname.startsWith(routes.SETTINGS)
    ) {
      if (Auth.isUserGlobalAdmin()) {
        tabIndex = globaladmin_tab_routes.indexOf(routes.SETTINGS);
      } else {
        tabIndex = employee_tab_routes.indexOf(routes.SETTINGS);
      }
    }
    tabIndex = tabIndex > 0 ? tabIndex : 0;
    return tabIndex;
  }

  handleChangeTabs = (event, indexOfTab) => {
    this.handleChangeDashboardTabs(indexOfTab);
  };

  handleChangeViews = (index) => {
    this.handleChangeDashboardTabs(index);
  };

  handleChangeDashboardTabs = (indexOfTab) => {
    const { visitorView, changeAssistantTab } = this.props;
    const { selectedVisit } = this.state;

    if (
      !Auth.isUserVisitor() &&
      this.state.indexOfDashboardTab !== indexOfTab
    ) {
      // Emit reload request event on tab change (for admin only)
      const reloadRoutes = [
        routes.MY_VISITS,
        routes.VISITORS,
        routes.MEETINGROOMS,
        routes.SETTINGS,
        routes.REPORTS,
      ];
      this.props.bus.emit('handleReloadData', reloadRoutes[indexOfTab]);
    }
    const parsed = Number.parseInt(indexOfTab, 10);

    changeAssistantTab(parsed);

    if (!Number.isNaN(parsed)) {
      this.setState({ indexOfDashboardTab: parsed });

      let nextTabPathname;
      if (Auth.isUserVisitor() || visitorView) {
        nextTabPathname = visitor_tab_routes[indexOfTab];
      } else if (Auth.isUserGlobalAdmin()) {
        nextTabPathname = globaladmin_tab_routes[indexOfTab];
      } else {
        nextTabPathname = employee_tab_routes[indexOfTab];
      }

      if (nextTabPathname.startsWith(routes.SETTINGS)) {
        nextTabPathname = this.lastSettingsPage;
      }
      this.props.history.push(nextTabPathname, {
        selectedVisit: selectedVisit,
      }); //Needs to be set here and on every change
    }
  };

  componentDidMount() {
    const { changeAssistantTab, populateUserAssistant } = this.props;
    //user from props might not be loaded yet
    const user = Auth.getUser();
    const index = this.getTabIndex();

    if (this.referrer === 'login') {
      this.props.history.replace(this.props.location.pathname); //Gets rid of the 'referrer' attribute
      var snackMessage = util.format(
        i18n.t('snackbar_login_success'),
        user.name
      );
      this.referrer = '';
      openSnackbar(snackMessage);

      if (
        user.role === 'employee' ||
        user.role === 'admin' ||
        user.role === 'assistant'
      ) {
        VisitService.getListOfUpcommingVisitor(user._id)
          .then(function (response) {
            if (response && response.length) {
              openSnackbar(i18n.t('snackbar_new_invitation'));
            }
          })
          .catch(function (err) {
            openSnackbar(err);
          });
      }
    }
    this.props.history.listen((location, action) => {
      const pathname = location.pathname;
      if (pathname.startsWith(routes.SETTINGS)) {
        if (pathname === routes.SETTINGS) {
          this.lastSettingsPage = routes.SETTINGS_USERS;
        } else {
          this.lastSettingsPage = pathname;
        }
      }
      if (action === 'POP') {
        this.setState({ indexOfDashboardTab: index });
      }
    });
    if (this.props.history.location.state) {
      this.setState({
        selectedVisit: this.props.history.location.state.selectedVisit,
      });
    }

    this.loadAssistantHelp(() => {
      UserAssistantService.getUserAssistant()
        .then((response) => {
          populateUserAssistant(response);
        })
        .catch((err) => {
          console.log(err);
          openSnackbar(err.message);
        })
        .finally(() => {
          changeAssistantTab(index);
        });
    });

    this.props.bus.on('languageChanged', this.loadAssistantHelp);
  }

  componentWillUnmount() {
    this.props.bus.off('languageChanged', this.loadAssistantHelp);
  }

  componentDidUpdate(prevProps) {
    if (this.props.visitorView !== prevProps.visitorView) {
      this.loadAssistantHelp(() => this.handleChangeViews(0));
      //Since components for each view remounts on visitorView change, we can reset the badges through here
      this.setState({ showBadges: [] });
    }
  }

  handleAddButtonClick = () => {
    let { pathname } = this.props.location;
    // TODO A better solution would be a redirect in case of closing and reopening the webpage
    if (pathname === '/') {
      if (Auth.isUserGlobalAdmin()) {
        pathname = routes.VISITORS;
      } else {
        pathname = routes.MY_VISITS;
      }
    }
    console.log('handleAddButtonClick', pathname);
    this.props.bus.emit('handleAddClick', pathname);
  };

  handleChangeVisit = (visit) => {
    this.setState({ selectedVisit: visit });
    const { pathname, state } = this.props.location;
    this.props.history.replace(pathname, { ...state, selectedVisit: visit }); //Needs to be set here and on every tab change
  };

  //### ASSISTANT ###
  //callback is needed to chain setstates, so the popup dialogs doesnt display before loading the contents first
  loadAssistantHelp = (callback) => {
    const { visitorView, populateAssistant } = this.props;

    let assistantTabs;
    let role;
    if (Auth.isUserVisitor() || visitorView) {
      assistantTabs = ['', '', '', ''];
      role = user_roles.VISITOR;
    } else if (Auth.isUserPorter()) {
      assistantTabs = [''];
      role = user_roles.PORTER;
    } else {
      assistantTabs = ['', '', '', '', ''];
      role = user_roles.EMPLOYEE;
    }

    TemplateService.getAssistantTemplates(role, i18n.language)
      .then((response) => {
        //Fill the appropriate spots
        response.forEach((item) => {
          assistantTabs[assistant_tabs[item.type]] = item.body;
        });

        populateAssistant(assistantTabs);
      })
      .catch((err) => {
        console.log(err);
        openSnackbar(err.message);
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  };

  updateExportMyVisitsType = (exportMyVisitsType) => {
    this.setState({ exportMyVisitsType });
  };

  downloadMyVisits = () => {
    const { user } = this.props;
    const { exportMyVisitsType } = this.state;
    VisitService.downloadMyVisits(user._id, exportMyVisitsType)
      .then((response) => {
        const timestamp = moment();
        fileDownload(
          response,
          `MyVisits-${exportMyVisitsType}${timestamp.toISOString()}.csv`
        );
      })
      .catch((error) => {
        console.log(error);
        openSnackbar(error.message);
      });
  };

  updateHasData = (index, hasData) => {
    this.setState({
      hasData: {
        ...this.state.hasData,
        [index]: hasData,
      },
    });
  };

  changeShowBadge = (index, value) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        draft.showBadges[index] = value;
      })
    );
  };

  updateHasData = (index, hasData) => {
    this.setState({
      hasData: {
        ...this.state.hasData,
        [index]: hasData,
      },
    });
  };

  render() {
    const { classes, visitorView, user } = this.props;
    const pathname = this.props.location.pathname;
    const { indexOfDashboardTab, selectedVisit, hasData, showBadges } =
      this.state;

    const isVisitor = Auth.isUserVisitor() || visitorView;
    const isPorter = Auth.isUserPorter();
    const isAdmin = Auth.isUserAdmin();
    const isGlobalAdmin = Auth.isUserGlobalAdmin();

    let tabNames = {};
    let tabContents = [];

    let noDataClass;

    // set true to show add button, set false to hide add button
    let fabButton = false;
    let exportSign = false;

    const pageWithoutAddButton = [
      routes.SETTINGS_SCHEDULEDJOBS,
      routes.SETTINGS_LOGS,
      routes.SETTINGS_CONFIGURATIONS,
      routes.SETTINGS_GLOBAL_CONFIGURATIONS,
    ].includes(pathname);

    // handle roles to set add buttons
    if (isVisitor) {
      fabButton = false; // user is visitor, hide all add buttons
      if (indexOfDashboardTab === 1) {
        noDataClass = classes.noVisitData;
      } else if (indexOfDashboardTab === 2) {
        noDataClass = classes.noItineraryData;
      } else if (indexOfDashboardTab === 3) {
        noDataClass = classes.noDocumentData;
      }
    } else if (isPorter) {
      fabButton = false; // user is porter, hide all add buttons
    } else if (isGlobalAdmin) {
      if (pageWithoutAddButton) {
        fabButton = false;
      } else {
        // indexes of visits and settings
        if ([0, 2].includes(indexOfDashboardTab)) {
          fabButton = true;
        }
      }
    } else {
      // all other roles, i.e. admin or employee
      if (indexOfDashboardTab === 0) {
        if (pageWithoutAddButton) {
          fabButton = false; // tab is open from scheduled jobs, hide add button
        } else {
          fabButton = true;
          exportSign = true;
        }
        noDataClass = classes.noMyVisitData;
      } else if (indexOfDashboardTab === 1) {
        // tab VISITORS
        if (pageWithoutAddButton) fabButton = false;
        // tab is open from scheduled jobs, hide add button
        else fabButton = true;
      } else if (indexOfDashboardTab === 2) {
        // tab MEETING ROOMS
        if (pageWithoutAddButton) fabButton = false;
        // tab is open from scheduled jobs, hide add button
        else if (!isAdmin) fabButton = user.allowMeetingRoomBooking;
        // only allowed user can schedule meeting room
        else fabButton = true;
      } else if (indexOfDashboardTab === 3) {
        // tab SETTINGS
        if (pageWithoutAddButton) fabButton = false;
        // tab is open from scheduled jobs, hide add button
        else if (
          pathname === routes.SETTINGS_COMPANY ||
          pathname === routes.SETTINGS_USERS
        ) {
          if (isAdmin) fabButton = true;
          // show add button at company settings for admin
          else fabButton = false; // no one else can see add button at company
        } else fabButton = true;
      } else if (indexOfDashboardTab === 4) {
        // tab REPORTS
        if (pageWithoutAddButton) fabButton = false;
        // tab is open from scheduled jobs, hide add button
        else fabButton = false; // no reason to show add button at reports
      }
      if (window.location.href.includes('sitetemplates') && !isGlobalAdmin)
        fabButton = false;
      if (window.location.href.includes('assistant') && !isGlobalAdmin)
        fabButton = false;
    }

    //else if (indexOfDashboardTab in [1] && (!user.allowMeetingRoomBooking || )) fabButton = false     // user has permisson to add meeting, shodd add button

    if (isVisitor) {
      tabNames = {
        explore: 'tab_visitor_explore',
        visits: 'tab_visitor_visit',
        itinerary: 'tab_visitor_itinerary',
        documents: 'tab_visitor_documents',
      };
      tabContents = [
        <ExplorePage
          user={user}
          selectedVisit={selectedVisit}
          handleChangeDashboardTabs={this.handleChangeDashboardTabs}
          onVisitChange={this.handleChangeVisit}
        />,
        <VisitPage
          selectedVisit={selectedVisit}
          onVisitChange={this.handleChangeVisit}
          updateHasData={this.updateHasData}
        />,
        <ItineraryPage
          selectedVisit={selectedVisit}
          onVisitChange={this.handleChangeVisit}
          updateHasData={this.updateHasData}
        />,
        <DocumentsPage
          user={user}
          updateHasData={this.updateHasData}
          changeShowBadge={this.changeShowBadge}
        />,
      ];
    } else if (isPorter) {
      tabNames = { visits: 'tab_visitors' };
      tabContents = [<VisitorPage />];
    } else if (isGlobalAdmin) {
      tabNames = {
        visits: 'tab_visitors',
        'meeting-rooms': 'tab_meeting_rooms',
        settings: 'tab_settings',
        reports: 'tab_reports',
      };
      tabContents = [
        <VisitorPage />,
        <MeetingRoomsPage />,
        <SettingsPage />,
        <ReportsPage />,
      ];
    } else {
      tabNames = {
        'my-visits': 'tab_my_visits',
        visits: 'tab_visitors',
        'meeting-rooms': 'tab_meeting_rooms',
        setting: 'tab_settings',
        reports: 'tab_reports',
      };
      tabContents = [
        <MyVisits
          user={user}
          updateExportType={this.updateExportMyVisitsType}
          updateHasData={this.updateHasData}
        />,
        <VisitorPage />,
        <MeetingRoomsPage />,
        <SettingsPage />,
        <ReportsPage />,
      ];
    }

    return (
      <CompanyDataProvider>
        <div
          className={classNames(
            classes.mainContainer,
            !hasData[indexOfDashboardTab] ? noDataClass : undefined
          )}
        >
          <TabbedView
            index={indexOfDashboardTab}
            onChangeTabs={this.handleChangeTabs}
            onChangeViews={this.handleChangeViews}
            tabNames={tabNames}
            tabContents={tabContents}
            showBadges={showBadges}
            transform="none"
          />
          {fabButton && (
            <VmsButtonFAB
              style={{ marginRight: '20px' }}
              data-cy={
                exportSign
                  ? 'dashboard-button-download'
                  : 'dashboard-button-add'
              }
              onClick={this.handleAddButtonClick}
            >
              {exportSign ? (
                <CloudDownload onClick={this.downloadMyVisits} />
              ) : (
                <Add />
              )}
            </VmsButtonFAB>
          )}
        </div>
      </CompanyDataProvider>
    );
  }
}

DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  visitorView: PropTypes.bool.isRequired,
  changeAssistantTab: PropTypes.func.isRequired,
  populateAssistant: PropTypes.func.isRequired,
  populateUserAssistant: PropTypes.func.isRequired,
};

export default compose(
  withBus(),
  withStyles(styles, { withTheme: true })
)(withRouter(DashboardPage));
