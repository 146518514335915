import PropTypes from 'prop-types';
import Auth from '../../modules/Auth';
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Hidden,
  Grid,
  IconButton,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import Help from '@material-ui/icons/Help';
import { Link } from 'react-router-dom';
import { VmsButtonMenu } from 'components';
import commonStyles from 'assets/jss/commonStyles';
import { routes } from '../../AppSettings';
import { colors } from 'assets/UISettings';
import classNames from 'classnames';
import { LanguageMenu } from 'components';

import loginLogo from 'assets/images/logo_login_mobile.png';
import { VmsAvatar } from 'components/common';

const styles = (theme) => ({
  ...commonStyles(theme),
  container: {
    position: 'absolute',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 48,
    },
    //marginTop: 64,
  },
  toolBar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  avatar: {
    width: 32,
    height: 32,
    [theme.breakpoints.up('md')]: {
      marginRight: 10,
    },
  },
  logo: {
    height: 24,
    [theme.breakpoints.up('sm')]: {
      height: 40,
    },
  },
  logoImg: {
    height: 24,
    [theme.breakpoints.up('sm')]: {
      height: 40,
    },
  },
  logoLogin: {
    // Override for xs and login the mobile logo
    position: 'absolute',
    left: 32,
    height: 48,
    display: 'flex',
    alignItems: 'center',
  },
  logoLoginImg: {
    height: 32,
  },
  appBar: {
    zIndex: 1110, // tabBar has 1102, also see https://material-ui.com/layout/basics/#z-index
    boxShadow: 'none',
  },
  tenantName: {
    fontSize: 'x-small',
  },
  topRightCorner: {
    position: 'absolute',
    right: 8,
    alignItems: 'center',
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.up('sm')]: {
      height: 52,
    },
  },
  profileMenuOpen: {
    backgroundColor: colors.greyFour,
    opacity: 1,
  },
  profileButton: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 'initial',
    },
  },
  assistantButton: {
    padding: 2,
    color: colors.whiteFive,
  },
  assistantButtonIcon: {
    fontSize: 38, //the icon itself has white borders and this is the easiest way to align it with avatar image
  },
});

const layout = (props) => {
  const {
    classes,
    menuButtonVisible,
    user,
    menuOpen,
    pathname,
    imgUrl,
    handleMenu,
    handleLanguage,
    empty,
    companyName,
    companyLogo,
    companyLogoMobile,
    handleAssistantClick,
  } = props;

  const showTitle = Auth.isUserAuthenticated() && !Auth.isUserVisitor();

  return (
    <>
      {!empty && (
        <AppBar
          position="fixed"
          color="inherit"
          classes={Auth.isUserAuthenticated() ? { root: classes.appBar } : null}
        >
          <Toolbar className={classes.toolBar}>
            <Hidden mdUp>
              {menuButtonVisible ? <VmsButtonMenu pathname={pathname} /> : null}
            </Hidden>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Hidden smUp>
                <Link
                  to={pathname}
                  className={
                    !Auth.isUserAuthenticated()
                      ? classes.logoLogin
                      : classes.logo
                  }
                >
                  {!Auth.isUserAuthenticated() ? (
                    <img
                      src={loginLogo}
                      alt="Company logo"
                      className={classes.logoLoginImg}
                    />
                  ) : (
                    companyLogoMobile && (
                      <img
                        src={companyLogoMobile}
                        alt="Company logo"
                        title={showTitle ? companyName : undefined}
                        className={classes.logoImg}
                      />
                    )
                  )}
                </Link>
              </Hidden>
              <Hidden xsDown>
                <Link to={pathname} className={classes.logo}>
                  {companyLogo && (
                    <img
                      src={companyLogo}
                      alt="Company logo"
                      title={showTitle ? companyName : undefined}
                      className={classes.logoImg}
                    />
                  )}
                </Link>
              </Hidden>
            </Grid>
            {Auth.isUserAuthenticated() && user && (
              <div className={classes.topRightCorner}>
                <Hidden xsDown>
                  {pathname !== routes.PROFILE && (
                    <IconButton
                      data-cy="nav-button-assistant"
                      className={classes.assistantButton}
                      onClick={handleAssistantClick}
                    >
                      <Help className={classes.assistantButtonIcon} />
                    </IconButton>
                  )}
                </Hidden>
                <Button
                  data-cy="nav-button-profile-menu"
                  onClick={handleMenu}
                  className={classNames([
                    menuOpen ? classes.profileMenuOpen : null,
                    classes.profileButton,
                  ])}
                >
                  <VmsAvatar
                    avatar={user.avatar}
                    alt="Avatar"
                    photoId={user.photo?._id}
                    src={imgUrl}
                    className={classes.avatar}
                  />
                  <Hidden smDown>
                    <Typography variant="body1" color="inherit">
                      {user.name}
                    </Typography>
                    {menuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                  </Hidden>
                </Button>
              </div>
            )}
            {(!Auth.isUserAuthenticated() || !user) && (
              <div className={classes.topRightCorner}>
                <LanguageMenu handleLanguage={handleLanguage} />
              </div>
            )}
          </Toolbar>
        </AppBar>
      )}
      {!empty ? (
        <div className={classes.container}>{props.children}</div>
      ) : (
        props.children
      )}
    </>
  );
};

layout.propTypes = {
  classes: PropTypes.object.isRequired,
  companyLayout: PropTypes.string,
  companyLogo: PropTypes.string,
  companyLogoMobile: PropTypes.string,
};

export default withStyles(styles)(layout);
