import { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';
import {
  DocumentService,
  ConfigurationService,
  SecurityItemsService,
} from 'services';
import { openSnackbar } from 'components/common/bars/SnackBar';
import { withRouter } from 'react-router-dom';
import Aux from 'hoc/Auxiliary';
import { VmsInfoBar } from 'components';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import QRCode from 'qrcode.react';
import {
  vms_application_routes,
  isMobileApp,
  configuration_group,
  configuration_tenant_settings_type,
  user_roles,
} from 'AppSettings';
import i18n from 'assets/i18n';
import Auth from 'modules/Auth';

const styles = (theme) => ({
  visitorText: {
    flexGrow: 1,
    color: 'black',
    width: '100%',
    fontWeight: 700,
    fontSize: 24,
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '10mm',
    margin: 0,
  },
  visitorName: {
    flexGrow: 1,
    width: '100%',
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
    verticalAlign: 'middle',
    margin: 0,
    paddingLeft: '5mm',
    paddingRight: '5mm',
  },
  visitorCompany: {
    flexGrow: 1,
    width: '100%',
    fontWeight: 500,
    fontSize: 8,
    textAlign: 'center',
    verticalAlign: 'middle',
    margin: 0,
  },
  visitorHost: {
    flexGrow: 1,
    width: '100%',
    fontWeight: 700,
    fontSize: 14,
    textAlign: 'center',
    verticalAlign: 'middle',
    margin: 0,
    paddingLeft: '5mm',
    paddingRight: '5mm',
  },
  visitorHostMedium: {
    fontWeight: 500,
  },
  logoBadge: {
    flexGrow: 1,
    width: '100%',
    textAlign: 'center',
    paddingTop: '4mm',
  },
  print: {
    // This is hardcoded also to fit printing from iphone
    overflow: 'hidden',
  },
});

class ProfileCardVisitPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoUrl: undefined,
      photoId: '',
      appleWalletDownloadUrl: null,
      googlePayJwt: null,
      securityItem: null,
      height: '90mm',
      width: '62mm',
      size: 148,
    };
  }

  handleGoToDashboard = (instant = false) => {
    if (instant) {
      this.props.history.go(-1);
    } else {
      setTimeout(() => {
        if (window.location.pathname.includes('/print/visit')) {
          if (
            Auth.getUser().role === user_roles.SIGNING_TABLET &&
            ![
              user_roles.ADMIN,
              user_roles.EMPLOYEE,
              user_roles.ASSISTANT,
            ].includes(this.props.visit.user.role)
          ) {
            this.props.history.push('/notification', {
              host: this.props.visit.host,
            });
          } else {
            this.props.history.go(-1);
          }
        }
      }, 5000);
    }
  };

  loadPhotoUrl = (photoId) => {
    const self = this;
    if (photoId) {
      DocumentService.getDocument(photoId)
        .then(function (response) {
          self.setState({
            photoId: photoId,
            photoUrl: response.downloadUrl,
          });
          // Wait for redraw after load of image
          setTimeout(self.print, 500);
        })
        .catch(function (error) {
          console.log(error);
          openSnackbar(error.message);
        });
    }
  };

  loadSecurityItem = async () => {
    // Load security item for visitor
    try {
      await this.loadData();
      if (
        [user_roles.ADMIN, user_roles.EMPLOYEE, user_roles.ASSISTANT].includes(
          this.props.visit.user.role
        ) &&
        this.props.visit.user.tenantId === Auth.getUser().tenantId
      ) {
        this.setState({ securityItem: this.props.visit.securityItem });
        setTimeout(this.print, 1500);
      } else {
        SecurityItemsService.getActiveSecurityItemForVisit(this.props.visit._id)
          .then((response) => {
            this.setState({
              securityItem: response.securityItem,
            });
            this.forceAuthenticateItem(response);

            setTimeout(this.print, 1500);
          })
          .catch(function (error) {
            console.log(error);
            openSnackbar(error.message);
          });
      }
    } catch (e) {
      console.log(e);
      //if error is thronw run the function again
      this.loadSecurityItem();
    }
  };

  forceAuthenticateItem = async (item) => {
    if (Auth.isUserAdmin() || Auth.isUserPorter()) {
      if (item.authenticationRequired) {
        try {
          SecurityItemsService.forceAuthenticateItem(item._id);
        } catch {}
      }
    }
  };

  print = async () => {
    const snackBars = document.querySelectorAll('.MuiCollapse-wrapper');
    snackBars.forEach((snackBar) => {
      snackBar.parentNode.removeChild(snackBar);
    });
    if (isMobileApp() === true) {
      /**
       * Global function used by device webview to register device token for push notifications.
       */
      window.printFinished = (printresponse) => {
        console.log(`Printing finished with ${printresponse}.`);
        //this.handleGoToDashboard();
        setTimeout(() => {
          // Give some time to go back first
          switch (printresponse) {
            case 'success':
            case 'true':
              break;
            case 'printer_not_configured':
              openSnackbar(i18n.t('printer_not_configured'));
              break;
            case 'printer_not_connected':
              openSnackbar(i18n.t('printer_not_connected'));
              break;
            default:
              openSnackbar(`${i18n.t('printer_error')} ${printresponse}`);
              break;
          }
        }, 500);
      };
      try {
        const response = await ConfigurationService.getConfigurationsForGroup(
          configuration_group.TENANT_SETTINGS
        );

        const printerUrl = response.find((c) => {
          return c.key === configuration_tenant_settings_type.PRINTER_URL;
        });
        if (printerUrl) {
          window.location = vms_application_routes.PRINT + printerUrl.value;
          //disabling this function for android devices
          //uncomment task #19097
          this.handleGoToDashboard();
        } else {
          openSnackbar(i18n.t('printer_not_configured'));
          //disabling this function for android devices
          //uncomment task #19097
          this.handleGoToDashboard();
        }
      } catch (error) {
        console.log(error);
        this.handleGoToDashboard();
      }
    } else {
      window.print();
      this.handleGoToDashboard();
    }
  };

  componentDidMount() {
    this.loadSecurityItem();
  }

  async loadData() {
    try {
      let badgeSize = await ConfigurationService.getValueByKey(
        'badge_print_size'
      );
      if (badgeSize) {
        badgeSize.value = badgeSize.value.replace('[', '').replace(']', '');
        let size = badgeSize.value.split(',');
        if (
          !isNaN(size[0].replace('mm', '')) &&
          !isNaN(size[1].replace('mm', '').trim())
        ) {
          let widthScale =
            this.state.width.replace('mm', '') /
            size[1].replace('mm', '').trim();

          this.state.height = size[0];
          this.state.width = size[1].trim();
          this.setState({
            ...this.state,
            width: size[1].trim(),
            height: size[0],
            size: this.state.size / widthScale,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  // componentDidUpdate(prevProps) {
  //   if (this.props.visit.user.photo !== prevProps.visit.user.photo) {
  //     this.loadPhotoUrl(this.props.visit.user.photo);
  //   }
  // }

  render() {
    const { classes, visit } = this.props;
    return (
      <PrintProvider>
        <Aux>
          <NoPrint>
            <VmsInfoBar
              backMode={true}
              onBack={() => {
                this.handleGoToDashboard(true);
              }}
            />
          </NoPrint>
          <Print>
            <div
              className={classes.print}
              style={{
                height: this.state.height,
                width: this.state.width,
              }}
            >
              <Grid
                container
                direction={'column'}
                justifyContent={'space-between'}
                alignItems={'center'}
                style={{ height: '100%' }}
              >
                {!visit.user?.role ? (
                  <Grid item className={classes.visitorText}>
                    VISITOR
                  </Grid>
                ) : (
                  <Grid item className={classes.visitorText}>
                    {visit.user.role.toUpperCase()}
                  </Grid>
                )}
                <Grid item className={classes.visitorName}>
                  {visit.user.name}
                  <br />
                  <span className={classes.visitorCompany}>
                    {visit.user.company}
                  </span>
                  <br />
                </Grid>

                {visit.host?.name && (
                  <Grid item className={classes.visitorHost}>
                    HOST :{' '}
                    <span className={classes.visitorHostMedium}>
                      {visit.host.name}
                    </span>
                  </Grid>
                )}
                <Grid item style={{ margin: 0 }}>
                  {this.state.securityItem && (
                    <QRCode
                      value={this.state.securityItem}
                      size={this.state.size}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          </Print>
        </Aux>
      </PrintProvider>
    );
  }
}

ProfileCardVisitPrint.propTypes = {
  classes: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(ProfileCardVisitPrint));
